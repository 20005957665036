.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: "Roboto", sans-serif;
  padding: 0px;
}

.icon {
  height: 100px;
  width: 100px;
  margin-top: 30px;
  margin-bottom: 40px;
  border-radius: 10px;
}

.input {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 250px;
  font-size: 16px;
}

.button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin: 10px 0 20px 0;
  opacity: 1;
}

.button:disabled,
.button-disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  opacity: 0.5;
}

.link {
  cursor: pointer;
  color: #0066cc;
  text-decoration: underline;
}

.title {
  font-size: 34px;
  color: #333;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 30px;
  padding-top: 0px;
}

.subTitle {
  font-size: 22px;
  color: #333;
  font-weight: 400;
  margin-bottom: 20px;
}

.formTitle {
  font-size: 26px;
  color: #333;
  margin-bottom: 20px;
}

.text {
  font-size: 14px;
  color: #333;
}

@media only screen and (max-width: 868px) and (orientation: landscape) {
  .container {
    padding-top: 120px;
    padding-bottom: 150px;
  }
  .icon {
    height: 70px;
    width: 70px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .container {
    padding-top: 20px;
    padding-bottom: 15px;
  }
  .title {
    font-size: 28px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-top: 0px;
  }

  .subTitle {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .formTitle {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .icon {
    height: 70px;
    width: 70px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .button {
    padding: 10px 20px;
    font-size: 16px;
    margin: 10px 0 20px 0;
  }
}
